import links from '@/api/links'

const state = () => ({
  data: [],
})

const mutations = {
  refresh(state, payload) {
    state.data = payload;
  },

  update(state, payload) {
    let index = state.data.findIndex(link => link.id === payload.id)
    if (index > -1) {
      state.data[index] = payload;
    } else {
      state.data.push(payload);
    }
  },
}

const actions = {
  async fetch({rootState, commit}) {
    const activeTenantId = rootState.auth.activeTenantId;
    const result = await links.get(activeTenantId);
    commit('refresh', result.data);
  },

  async fetchOne({rootState, commit}, linkId) {
    const activeTenantId = rootState.auth.activeTenantId;
    const result = await links.getOne(activeTenantId, linkId);
    commit('update', result.data);
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}