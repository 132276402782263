import users from "../../api/users";

const state = () => ({

    data: [],
    selected: '',

})

const getters = {

    userList: (state) => state.data,
    user: (state) => state.data.filter(user => user.id === state.selected)

}

const mutations = {

    refresh: (state, payload) => {
        state.data = payload;
    },

    update: (state, payload) => {
        let index = state.data.findIndex(user => user.id === payload.id);
        if (index > -1) {
            state.data[index] = payload;
        } else {
            state.data.push(payload);
        }
    },

    delete: (state, payload) => {
        let index = state.data.findIndex(user => user.id === payload.id);
        state.data.splice(index, 1);
    },

    setUser: (state, user) => {
        state.selected = user.id;
    },

    unsetUser: (state) => {
        state.selected = '';
    },

}

const actions = {

    fetch: async ({ rootState, commit }) => {
        const activeTenantId = rootState.auth.activeTenantId;
        const result = await users.get(activeTenantId);
        commit('refresh', result.data);
    },

    create: async ({ rootState, commit }, user) => {
        const activeTenantId = rootState.auth.activeTenantId;
        const result = await users.create(activeTenantId, user);
        commit('update', result.data);
    },

    update: async ({ rootState, commit }, user) => {
        const activeTenantId = rootState.auth.activeTenantId;
        const result = await users.update(activeTenantId, user);
        commit('update', result.data);
    },

    me: async ({ commit }, user) => {
        const result = await users.me(user);
        commit('update', result.data);
    },

    delete: async ({ rootState, commit }, user) => {
        const activeTenantId = rootState.auth.activeTenantId;
        const result = await users.delete(activeTenantId, user);
        commit('delete', result.data);
    },

}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}