<template>
  <svg
    version="1.1"
    id="Laag_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 218 64"
    style="enable-background: new 0 0 218 64"
    xml:space="preserve"
  >
    <path
      id="Path_48"
      class="st0"
      d="M23.5,25.9c0,0.2,0,0.5-0.1,0.8H6.8c0.2,1.4,1,2.6,2,3.5c1.1,0.8,2.5,1.2,3.8,1.2
 c1,0,2.1-0.2,3-0.6c1-0.5,2-1.1,2.8-1.9l3.9,3.4c-1.1,1.4-2.5,2.5-4.1,3.3c-1.8,0.8-3.7,1.2-5.7,1.2c-1.7,0-3.4-0.3-5-0.9
 c-1.5-0.6-2.8-1.4-4-2.5c-1.1-1.1-2-2.4-2.6-3.9c-0.6-1.6-1-3.3-0.9-5v-0.1c0-1.6,0.3-3.3,0.9-4.8c0.6-1.5,1.4-2.8,2.4-4
 c1.1-1.1,2.3-2,3.7-2.7c1.5-0.7,3.2-1,4.8-1c1.8,0,3.6,0.3,5.2,1.1c1.4,0.7,2.7,1.7,3.6,2.9c1,1.2,1.7,2.6,2.1,4.1
 c0.5,1.6,0.7,3.2,0.7,4.9v0.1C23.5,25.4,23.5,25.7,23.5,25.9z M15.3,18.9c-0.9-0.9-2.1-1.5-3.4-1.4c-1.3-0.1-2.6,0.4-3.5,1.4
 c-0.9,1-1.5,2.3-1.7,3.7h10.2C16.8,21.2,16.2,19.9,15.3,18.9L15.3,18.9z"
    />
    <path
      id="Path_49"
      class="st0"
      d="M40.6,36.3v-2.6c-0.8,0.9-1.9,1.7-3,2.2c-1.3,0.6-2.8,0.9-4.2,0.8c-1.1,0-2.2-0.1-3.2-0.5
 c-1-0.3-1.8-0.8-2.6-1.4c-0.7-0.6-1.4-1.4-1.8-2.3c-0.4-1-0.7-2.1-0.6-3.1v-0.1c0-1.2,0.2-2.3,0.7-3.4c0.5-0.9,1.1-1.7,2-2.4
 c0.9-0.7,1.9-1.1,3-1.4c1.3-0.3,2.6-0.5,3.9-0.5c1.1,0,2.1,0.1,3.2,0.3c0.9,0.2,1.8,0.4,2.7,0.7v-0.4c0.1-1.2-0.4-2.4-1.3-3.2
 c-1.1-0.8-2.5-1.2-3.8-1.1c-1.2,0-2.3,0.1-3.5,0.3c-1.1,0.2-2.1,0.6-3.2,1l-1.7-5.1c1.3-0.6,2.7-1,4.1-1.4c1.7-0.4,3.4-0.6,5.2-0.5
 c3.7,0,6.4,0.9,8.1,2.7s2.5,4.3,2.5,7.5v13.8L40.6,36.3z M40.7,26.7c-0.6-0.3-1.3-0.5-2-0.6c-0.8-0.2-1.6-0.2-2.3-0.2
 c-1.2-0.1-2.4,0.2-3.4,0.8c-0.8,0.5-1.3,1.5-1.3,2.4v0.1c0,0.8,0.3,1.6,1,2.1c0.8,0.5,1.7,0.8,2.7,0.8c1.4,0.1,2.7-0.3,3.9-1.2
 c0.9-0.7,1.5-1.8,1.5-3L40.7,26.7z"
    />
    <path
      id="Path_50"
      class="st0"
      d="M67.9,32.5c-0.4,0.9-1.1,1.8-1.9,2.4c-0.9,0.6-1.9,1.1-2.9,1.4c-1.2,0.3-2.4,0.5-3.6,0.5
 c-3.7,0-7.3-1.3-10.3-3.6l2.9-4.4c1.2,0.9,2.5,1.6,3.9,2.1c1.2,0.4,2.4,0.7,3.6,0.7c0.8,0.1,1.5-0.1,2.2-0.5
 c0.4-0.3,0.7-0.8,0.7-1.3v-0.1c0-0.4-0.1-0.7-0.4-0.9c-0.3-0.3-0.7-0.5-1.1-0.7c-0.5-0.2-1-0.4-1.6-0.6c-0.6-0.2-1.2-0.4-1.9-0.6
 c-0.9-0.2-1.7-0.5-2.5-0.9c-0.8-0.3-1.6-0.8-2.3-1.3c-0.7-0.6-1.3-1.2-1.7-2c-0.5-0.9-0.7-1.9-0.6-2.9v-0.1
 c-0.1-2.2,0.9-4.2,2.6-5.6c0.8-0.7,1.8-1.2,2.8-1.5c1.1-0.3,2.3-0.5,3.4-0.5c1.6,0,3.1,0.3,4.6,0.7c1.5,0.5,3,1.1,4.3,2l-2.6,4.7
 c-1.1-0.6-2.3-1.2-3.5-1.6c-1-0.4-2-0.6-3-0.6c-0.7,0-1.3,0.1-1.9,0.5c-0.4,0.3-0.6,0.7-0.6,1.2v0.1c0,0.3,0.1,0.6,0.4,0.9
 c0.3,0.3,0.7,0.5,1.1,0.7c0.5,0.2,1,0.5,1.5,0.6c0.6,0.2,1.2,0.4,1.9,0.6c0.8,0.3,1.7,0.6,2.5,1c0.8,0.4,1.6,0.8,2.3,1.4
 c0.7,0.5,1.3,1.2,1.7,2c0.5,0.9,0.7,1.8,0.7,2.8v0.1C68.6,30.3,68.4,31.4,67.9,32.5z"
    />
    <path
      id="Path_51"
      class="st0"
      d="M85,36.9C84.6,38,84.2,39,83.6,40c-0.4,0.8-1,1.5-1.7,2.1c-0.6,0.5-1.3,0.9-2.1,1.2
 c-0.9,0.3-1.8,0.4-2.7,0.4c-1.1,0-2.2-0.1-3.3-0.4c-1-0.3-1.9-0.7-2.7-1.2l2.3-4.9c0.5,0.3,0.9,0.5,1.5,0.7c0.5,0.2,1,0.3,1.5,0.3
 c0.5,0,1-0.1,1.4-0.3c0.4-0.3,0.8-0.7,1-1.2l-9.4-23.9h7.2l5.5,16.2l5.2-16.2h7.1L85,36.9z"
    />
    <path id="Path_52" class="st0" d="M97.4,36.3V3.9h3.4v32.4H97.4z" />
    <path
      id="Path_53"
      class="st0"
      d="M106.9,8.3V4.6h3.9v3.8H106.9z M107.1,36.3v-23h3.4v23L107.1,36.3z"
    />
    <path
      id="Path_54"
      class="st0"
      d="M120,36.3h-3.4v-23h3.4v4c0.8-1.3,1.8-2.3,3-3.2c1.5-0.9,3.2-1.4,4.9-1.3c1.3,0,2.5,0.2,3.7,0.7
 c1.1,0.4,2,1.1,2.8,1.9c0.8,0.8,1.4,1.8,1.7,2.9c0.4,1.2,0.6,2.5,0.6,3.7v14.2h-3.4V22.9c0.1-1.8-0.5-3.6-1.7-5.1
 c-1.2-1.3-3-2-4.7-1.9c-0.9,0-1.9,0.2-2.7,0.5c-0.8,0.3-1.6,0.8-2.2,1.5c-0.6,0.7-1.1,1.4-1.5,2.3c-0.4,0.9-0.5,1.9-0.5,2.9
 L120,36.3z"
    />
    <path
      id="Path_55"
      class="st0"
      d="M162.6,36.3h-4.1l-8.3-10.7l-4.7,4.7v6h-3.4V3.9h3.4v22.4L158,13.4h4.3l-9.7,9.9L162.6,36.3z"
    />
    <path
      id="Path_56"
      class="st0"
      d="M168.2,0.9c0.5,0,1,0.1,1.4,0.4c0.5,0.2,0.8,0.6,1.1,1.1c0.5,0.9,0.5,2,0,2.9
 c-0.3,0.5-0.6,0.8-1.1,1.1c-0.9,0.5-2,0.5-2.9,0c-0.5-0.3-0.8-0.6-1.1-1.1c-0.5-0.9-0.5-2,0-2.9c0.3-0.5,0.6-0.8,1.1-1.1
 C167.2,1,167.7,0.9,168.2,0.9z M168.2,1.4c-0.4,0-0.8,0.1-1.2,0.3c-0.4,0.2-0.7,0.5-0.9,0.9c-0.4,0.8-0.4,1.7,0,2.4
 c0.2,0.4,0.5,0.7,0.9,0.9c0.8,0.4,1.7,0.4,2.4,0c0.4-0.2,0.7-0.5,0.9-0.9c0.4-0.8,0.4-1.7,0-2.4c-0.2-0.4-0.5-0.7-0.9-0.9
 C169.1,1.5,168.7,1.4,168.2,1.4L168.2,1.4z M166.9,5.4V2.3h1.1c0.3,0,0.5,0,0.8,0.1c0.2,0.1,0.3,0.2,0.4,0.3
 c0.1,0.1,0.1,0.3,0.1,0.5c0,0.2-0.1,0.4-0.2,0.6C169,3.9,168.7,4,168.5,4c0.1,0,0.2,0.1,0.3,0.2c0.2,0.2,0.3,0.4,0.5,0.6l0.4,0.6
 H169l-0.3-0.5c-0.1-0.3-0.3-0.5-0.5-0.7c-0.1-0.1-0.3-0.1-0.4-0.1h-0.3v1.3L166.9,5.4z M167.5,3.7h0.6c0.2,0,0.4,0,0.6-0.1
 c0.2-0.1,0.2-0.4,0.1-0.6c-0.1-0.1-0.1-0.1-0.2-0.2c-0.2,0-0.3-0.1-0.5-0.1h-0.6L167.5,3.7z"
    />
    <path
      class="st0"
      d="M8.6,60.4l-1-3.1c-0.1-0.2-0.2-0.6-0.3-1.3h0c-0.1,0.6-0.2,1-0.3,1.3l-1,3.1H5l-1.5-5.4h0.8
 c0.3,1.3,0.6,2.4,0.8,3.1s0.3,1.2,0.3,1.4h0c0-0.2,0.1-0.4,0.2-0.7c0.1-0.3,0.1-0.5,0.2-0.7l1-3.1h0.9l1,3.1c0.2,0.6,0.3,1,0.4,1.4
 h0c0-0.1,0-0.3,0.1-0.5s0.4-1.6,1-3.9h0.8l-1.5,5.4H8.6z"
    />
    <path
      class="st0"
      d="M14.4,60.5c-0.8,0-1.4-0.2-1.9-0.7s-0.7-1.2-0.7-2c0-0.9,0.2-1.5,0.6-2.1s1-0.8,1.7-0.8c0.7,0,1.2,0.2,1.6,0.7
 c0.4,0.4,0.6,1,0.6,1.7v0.5h-3.7c0,0.6,0.2,1.1,0.5,1.4s0.7,0.5,1.3,0.5c0.6,0,1.1-0.1,1.7-0.4v0.7c-0.3,0.1-0.6,0.2-0.8,0.3
 C15.1,60.5,14.8,60.5,14.4,60.5z M14.2,55.6c-0.4,0-0.8,0.1-1,0.4s-0.4,0.7-0.5,1.2h2.8c0-0.5-0.1-0.9-0.3-1.2
 C14.9,55.8,14.6,55.6,14.2,55.6z"
    />
    <path class="st0" d="M21.4,60.4h-0.8v-7.6h0.8V60.4z" />
    <path
      class="st0"
      d="M23.2,53.6c0-0.2,0-0.3,0.1-0.4c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.2,0,0.3,0.1s0.1,0.2,0.1,0.4s0,0.3-0.1,0.4
 c-0.1,0.1-0.2,0.1-0.3,0.1c-0.1,0-0.3,0-0.3-0.1C23.3,53.9,23.2,53.8,23.2,53.6z M24.1,60.4h-0.8v-5.4h0.8V60.4z"
    />
    <path
      class="st0"
      d="M29.6,60.4V57c0-0.4-0.1-0.8-0.3-1s-0.5-0.3-0.9-0.3c-0.6,0-1,0.2-1.2,0.5s-0.4,0.8-0.4,1.5v2.8h-0.8v-5.4h0.7
 l0.1,0.7h0c0.2-0.3,0.4-0.5,0.7-0.6c0.3-0.1,0.6-0.2,1-0.2c0.6,0,1.1,0.2,1.5,0.5c0.3,0.3,0.5,0.8,0.5,1.5v3.5H29.6z"
    />
    <path
      class="st0"
      d="M33,57.7c0.1-0.2,0.4-0.5,0.6-0.8l1.7-1.8h1l-2.2,2.3l2.3,3.1h-1l-1.9-2.5L33,58.4v2h-0.8v-7.6H33v4
 C33,57,33,57.3,33,57.7L33,57.7z"
    />
    <path
      class="st0"
      d="M39.5,55.1h0.9l1.2,3.1c0.3,0.7,0.4,1.2,0.5,1.5h0c0-0.2,0.1-0.5,0.3-0.9c0.1-0.4,0.6-1.6,1.3-3.7h0.9l-2.3,6.1
 c-0.2,0.6-0.5,1-0.8,1.3c-0.3,0.3-0.7,0.4-1.1,0.4c-0.2,0-0.5,0-0.7-0.1v-0.6c0.2,0,0.4,0.1,0.6,0.1c0.6,0,1-0.3,1.2-0.9l0.3-0.8
 L39.5,55.1z"
    />
    <path
      class="st0"
      d="M50.1,57.7c0,0.9-0.2,1.6-0.7,2c-0.4,0.5-1,0.7-1.8,0.7c-0.5,0-0.9-0.1-1.3-0.3s-0.7-0.5-0.9-1
 s-0.3-0.9-0.3-1.5c0-0.9,0.2-1.6,0.7-2c0.4-0.5,1-0.7,1.8-0.7c0.7,0,1.3,0.2,1.8,0.7S50.1,56.9,50.1,57.7z M46,57.7
 c0,0.7,0.1,1.2,0.4,1.6s0.7,0.5,1.2,0.5s0.9-0.2,1.2-0.5c0.3-0.4,0.4-0.9,0.4-1.6c0-0.7-0.1-1.2-0.4-1.5c-0.3-0.4-0.7-0.5-1.2-0.5
 c-0.5,0-0.9,0.2-1.2,0.5S46,57,46,57.7z"
    />
    <path
      class="st0"
      d="M52.4,55.1v3.5c0,0.4,0.1,0.8,0.3,1s0.5,0.3,0.9,0.3c0.6,0,1-0.2,1.2-0.5s0.4-0.8,0.4-1.5v-2.8h0.8v5.4h-0.7
 l-0.1-0.7h0c-0.2,0.3-0.4,0.5-0.7,0.6s-0.6,0.2-1,0.2c-0.7,0-1.1-0.2-1.5-0.5s-0.5-0.8-0.5-1.5v-3.5H52.4z"
    />
    <path
      class="st0"
      d="M60.4,55c0.2,0,0.5,0,0.6,0.1l-0.1,0.8c-0.2,0-0.4-0.1-0.6-0.1c-0.4,0-0.8,0.2-1.1,0.5s-0.5,0.8-0.5,1.3v2.9
 h-0.8v-5.4h0.7l0.1,1h0c0.2-0.3,0.4-0.6,0.7-0.8S60,55,60.4,55z"
    />
    <path
      class="st0"
      d="M68.3,59c0,0.5-0.2,0.9-0.6,1.2s-0.9,0.4-1.6,0.4c-0.7,0-1.3-0.1-1.7-0.3v-0.8c0.3,0.1,0.5,0.2,0.8,0.3
 s0.6,0.1,0.9,0.1c0.4,0,0.7-0.1,1-0.2c0.2-0.1,0.3-0.3,0.3-0.6c0-0.2-0.1-0.4-0.3-0.5c-0.2-0.1-0.5-0.3-1.1-0.5
 c-0.5-0.2-0.9-0.3-1.1-0.5c-0.2-0.1-0.4-0.3-0.5-0.5s-0.2-0.4-0.2-0.6c0-0.4,0.2-0.8,0.5-1c0.4-0.3,0.8-0.4,1.5-0.4
 c0.6,0,1.1,0.1,1.7,0.4L67.9,56c-0.5-0.2-1-0.3-1.5-0.3c-0.4,0-0.7,0.1-0.9,0.2s-0.3,0.3-0.3,0.5c0,0.1,0,0.3,0.1,0.4
 s0.2,0.2,0.4,0.3s0.5,0.2,0.9,0.4c0.6,0.2,1.1,0.5,1.3,0.7S68.3,58.6,68.3,59z"
    />
    <path
      class="st0"
      d="M74.4,57.7c0,0.9-0.2,1.6-0.7,2c-0.4,0.5-1,0.7-1.8,0.7c-0.5,0-0.9-0.1-1.3-0.3s-0.7-0.5-0.9-1
 s-0.3-0.9-0.3-1.5c0-0.9,0.2-1.6,0.7-2c0.4-0.5,1-0.7,1.8-0.7c0.7,0,1.3,0.2,1.8,0.7S74.4,56.9,74.4,57.7z M70.3,57.7
 c0,0.7,0.1,1.2,0.4,1.6s0.7,0.5,1.2,0.5s0.9-0.2,1.2-0.5c0.3-0.4,0.4-0.9,0.4-1.6c0-0.7-0.1-1.2-0.4-1.5c-0.3-0.4-0.7-0.5-1.2-0.5
 c-0.5,0-0.9,0.2-1.2,0.5S70.3,57,70.3,57.7z"
    />
    <path
      class="st0"
      d="M78.3,55.7H77v4.7h-0.8v-4.7h-1v-0.4l1-0.3v-0.3c0-1.3,0.6-2,1.7-2c0.3,0,0.6,0.1,1,0.2l-0.2,0.6
 c-0.3-0.1-0.6-0.2-0.8-0.2c-0.3,0-0.5,0.1-0.7,0.3c-0.1,0.2-0.2,0.5-0.2,1v0.3h1.4V55.7z"
    />
    <path
      class="st0"
      d="M81.2,59.8c0.1,0,0.3,0,0.4,0c0.1,0,0.2,0,0.3-0.1v0.6c-0.1,0-0.2,0.1-0.4,0.1c-0.2,0-0.3,0-0.5,0
 c-1,0-1.6-0.5-1.6-1.6v-3.2h-0.8v-0.4l0.8-0.3l0.3-1.1h0.5v1.2h1.6v0.6h-1.6v3.1c0,0.3,0.1,0.6,0.2,0.7S80.9,59.8,81.2,59.8z"
    />
    <path
      class="st0"
      d="M87.5,60.4l-1-3.1c-0.1-0.2-0.2-0.6-0.3-1.3h0c-0.1,0.6-0.2,1-0.3,1.3l-1,3.1h-0.9l-1.5-5.4h0.8
 c0.3,1.3,0.6,2.4,0.8,3.1s0.3,1.2,0.3,1.4h0c0-0.2,0.1-0.4,0.2-0.7c0.1-0.3,0.1-0.5,0.2-0.7l1-3.1h0.9l1,3.1c0.2,0.6,0.3,1,0.4,1.4
 h0c0-0.1,0-0.3,0.1-0.5s0.4-1.6,1-3.9h0.8l-1.5,5.4H87.5z"
    />
    <path
      class="st0"
      d="M94.3,60.4l-0.2-0.8h0c-0.3,0.3-0.5,0.6-0.8,0.7c-0.3,0.1-0.6,0.2-1,0.2c-0.5,0-0.9-0.1-1.2-0.4
 s-0.5-0.7-0.5-1.2c0-1.1,0.9-1.6,2.6-1.7l0.9,0v-0.3c0-0.4-0.1-0.7-0.3-0.9c-0.2-0.2-0.5-0.3-0.9-0.3c-0.4,0-1,0.1-1.5,0.4l-0.2-0.6
 c0.3-0.1,0.6-0.3,0.9-0.3S92.7,55,93,55c0.6,0,1.1,0.1,1.4,0.4s0.5,0.7,0.5,1.4v3.7H94.3z M92.5,59.8c0.5,0,0.9-0.1,1.2-0.4
 s0.4-0.7,0.4-1.2v-0.5l-0.8,0c-0.6,0-1.1,0.1-1.4,0.3c-0.3,0.2-0.4,0.5-0.4,0.8c0,0.3,0.1,0.5,0.3,0.7S92.2,59.8,92.5,59.8z"
    />
    <path
      class="st0"
      d="M99.2,55c0.2,0,0.5,0,0.6,0.1l-0.1,0.8c-0.2,0-0.4-0.1-0.6-0.1c-0.4,0-0.8,0.2-1.1,0.5s-0.5,0.8-0.5,1.3v2.9
 h-0.8v-5.4h0.7l0.1,1h0c0.2-0.3,0.4-0.6,0.7-0.8S98.8,55,99.2,55z"
    />
    <path
      class="st0"
      d="M103.2,60.5c-0.8,0-1.4-0.2-1.9-0.7s-0.7-1.2-0.7-2c0-0.9,0.2-1.5,0.6-2.1s1-0.8,1.7-0.8c0.7,0,1.2,0.2,1.6,0.7
 c0.4,0.4,0.6,1,0.6,1.7v0.5h-3.7c0,0.6,0.2,1.1,0.5,1.4s0.7,0.5,1.3,0.5c0.6,0,1.1-0.1,1.7-0.4v0.7c-0.3,0.1-0.6,0.2-0.8,0.3
 C103.9,60.5,103.5,60.5,103.2,60.5z M103,55.6c-0.4,0-0.8,0.1-1,0.4s-0.4,0.7-0.5,1.2h2.8c0-0.5-0.1-0.9-0.3-1.2
 C103.7,55.8,103.4,55.6,103,55.6z"
    />
    <path
      class="st0"
      d="M106.5,59.9c0-0.2,0-0.4,0.1-0.5c0.1-0.1,0.2-0.2,0.4-0.2c0.2,0,0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.3,0.2,0.5
 c0,0.2-0.1,0.4-0.2,0.5s-0.3,0.2-0.4,0.2c-0.2,0-0.3-0.1-0.4-0.2C106.6,60.3,106.5,60.1,106.5,59.9z"
    />
    <path
      class="st0"
      d="M114.4,60.5c-0.8,0-1.4-0.2-1.9-0.7c-0.5-0.5-0.7-1.2-0.7-2c0-0.9,0.2-1.5,0.6-2.1c0.4-0.5,1-0.8,1.7-0.8
 c0.7,0,1.2,0.2,1.6,0.7c0.4,0.4,0.6,1,0.6,1.7v0.5h-3.7c0,0.6,0.2,1.1,0.5,1.4c0.3,0.3,0.7,0.5,1.3,0.5c0.6,0,1.1-0.1,1.7-0.4v0.7
 c-0.3,0.1-0.6,0.2-0.8,0.3C115.1,60.5,114.8,60.5,114.4,60.5z M114.2,55.6c-0.4,0-0.8,0.1-1,0.4c-0.3,0.3-0.4,0.7-0.5,1.2h2.8
 c0-0.5-0.1-0.9-0.3-1.2C115,55.8,114.6,55.6,114.2,55.6z"
    />
    <path
      class="st0"
      d="M121.2,60.4l-0.2-0.8h0c-0.3,0.3-0.5,0.6-0.8,0.7c-0.3,0.1-0.6,0.2-1,0.2c-0.5,0-0.9-0.1-1.2-0.4
 c-0.3-0.3-0.5-0.7-0.5-1.2c0-1.1,0.9-1.6,2.6-1.7l0.9,0v-0.3c0-0.4-0.1-0.7-0.3-0.9c-0.2-0.2-0.5-0.3-0.9-0.3c-0.4,0-1,0.1-1.5,0.4
 l-0.2-0.6c0.3-0.1,0.6-0.3,0.9-0.3c0.3-0.1,0.6-0.1,0.9-0.1c0.6,0,1.1,0.1,1.4,0.4c0.3,0.3,0.5,0.7,0.5,1.4v3.7H121.2z M119.4,59.8
 c0.5,0,0.9-0.1,1.2-0.4c0.3-0.3,0.4-0.7,0.4-1.2v-0.5l-0.8,0c-0.6,0-1.1,0.1-1.4,0.3c-0.3,0.2-0.4,0.5-0.4,0.8
 c0,0.3,0.1,0.5,0.3,0.7C118.8,59.8,119.1,59.8,119.4,59.8z"
    />
    <path
      class="st0"
      d="M127.1,59c0,0.5-0.2,0.9-0.6,1.2s-0.9,0.4-1.6,0.4c-0.7,0-1.3-0.1-1.7-0.3v-0.8c0.3,0.1,0.5,0.2,0.8,0.3
 c0.3,0.1,0.6,0.1,0.9,0.1c0.4,0,0.7-0.1,1-0.2c0.2-0.1,0.3-0.3,0.3-0.6c0-0.2-0.1-0.4-0.3-0.5c-0.2-0.1-0.5-0.3-1.1-0.5
 c-0.5-0.2-0.9-0.3-1.1-0.5c-0.2-0.1-0.4-0.3-0.5-0.5c-0.1-0.2-0.2-0.4-0.2-0.6c0-0.4,0.2-0.8,0.5-1c0.4-0.3,0.8-0.4,1.5-0.4
 c0.6,0,1.1,0.1,1.7,0.4l-0.3,0.7c-0.5-0.2-1-0.3-1.5-0.3c-0.4,0-0.7,0.1-0.9,0.2s-0.3,0.3-0.3,0.5c0,0.1,0,0.3,0.1,0.4
 c0.1,0.1,0.2,0.2,0.4,0.3s0.5,0.2,0.9,0.4c0.6,0.2,1.1,0.5,1.3,0.7C127,58.3,127.1,58.6,127.1,59z"
    />
    <path
      class="st0"
      d="M127.7,55.1h0.9l1.2,3.1c0.3,0.7,0.4,1.2,0.5,1.5h0c0-0.2,0.1-0.5,0.3-0.9c0.1-0.4,0.6-1.6,1.3-3.7h0.9
 l-2.3,6.1c-0.2,0.6-0.5,1-0.8,1.3c-0.3,0.3-0.7,0.4-1.1,0.4c-0.2,0-0.5,0-0.7-0.1v-0.6c0.2,0,0.4,0.1,0.6,0.1c0.6,0,1-0.3,1.2-0.9
 l0.3-0.8L127.7,55.1z"
    />
    <path
      class="st0"
      d="M134.5,59.3l0.1,0.1c-0.1,0.3-0.2,0.7-0.4,1.1c-0.2,0.4-0.3,0.8-0.5,1.2h-0.6c0.1-0.3,0.2-0.8,0.3-1.3
 c0.1-0.5,0.2-0.9,0.2-1.2H134.5z"
    />
    <path
      class="st0"
      d="M141.4,55.7H140v4.7h-0.8v-4.7h-1v-0.4l1-0.3v-0.3c0-1.3,0.6-2,1.7-2c0.3,0,0.6,0.1,1,0.2l-0.2,0.6
 c-0.3-0.1-0.6-0.2-0.8-0.2c-0.3,0-0.5,0.1-0.7,0.3c-0.1,0.2-0.2,0.5-0.2,1v0.3h1.4V55.7z"
    />
    <path
      class="st0"
      d="M145.8,60.4l-0.2-0.8h0c-0.3,0.3-0.5,0.6-0.8,0.7c-0.3,0.1-0.6,0.2-1,0.2c-0.5,0-0.9-0.1-1.2-0.4
 c-0.3-0.3-0.5-0.7-0.5-1.2c0-1.1,0.9-1.6,2.6-1.7l0.9,0v-0.3c0-0.4-0.1-0.7-0.3-0.9c-0.2-0.2-0.5-0.3-0.9-0.3c-0.4,0-1,0.1-1.5,0.4
 l-0.2-0.6c0.3-0.1,0.6-0.3,0.9-0.3c0.3-0.1,0.6-0.1,0.9-0.1c0.6,0,1.1,0.1,1.4,0.4c0.3,0.3,0.5,0.7,0.5,1.4v3.7H145.8z M144,59.8
 c0.5,0,0.9-0.1,1.2-0.4c0.3-0.3,0.4-0.7,0.4-1.2v-0.5l-0.8,0c-0.6,0-1.1,0.1-1.4,0.3c-0.3,0.2-0.4,0.5-0.4,0.8
 c0,0.3,0.1,0.5,0.3,0.7C143.4,59.8,143.6,59.8,144,59.8z"
    />
    <path
      class="st0"
      d="M151.7,59c0,0.5-0.2,0.9-0.6,1.2s-0.9,0.4-1.6,0.4c-0.7,0-1.3-0.1-1.7-0.3v-0.8c0.3,0.1,0.5,0.2,0.8,0.3
 c0.3,0.1,0.6,0.1,0.9,0.1c0.4,0,0.7-0.1,1-0.2c0.2-0.1,0.3-0.3,0.3-0.6c0-0.2-0.1-0.4-0.3-0.5c-0.2-0.1-0.5-0.3-1.1-0.5
 c-0.5-0.2-0.9-0.3-1.1-0.5c-0.2-0.1-0.4-0.3-0.5-0.5c-0.1-0.2-0.2-0.4-0.2-0.6c0-0.4,0.2-0.8,0.5-1c0.4-0.3,0.8-0.4,1.5-0.4
 c0.6,0,1.1,0.1,1.7,0.4l-0.3,0.7c-0.5-0.2-1-0.3-1.5-0.3c-0.4,0-0.7,0.1-0.9,0.2s-0.3,0.3-0.3,0.5c0,0.1,0,0.3,0.1,0.4
 c0.1,0.1,0.2,0.2,0.4,0.3s0.5,0.2,0.9,0.4c0.6,0.2,1.1,0.5,1.3,0.7C151.5,58.3,151.7,58.6,151.7,59z"
    />
    <path
      class="st0"
      d="M154.8,59.8c0.1,0,0.3,0,0.4,0c0.1,0,0.2,0,0.3-0.1v0.6c-0.1,0-0.2,0.1-0.4,0.1c-0.2,0-0.3,0-0.5,0
 c-1,0-1.6-0.5-1.6-1.6v-3.2h-0.8v-0.4l0.8-0.3l0.3-1.1h0.5v1.2h1.6v0.6H154v3.1c0,0.3,0.1,0.6,0.2,0.7S154.6,59.8,154.8,59.8z"
    />
    <path
      class="st0"
      d="M162.8,60.4l-0.2-0.8h0c-0.3,0.3-0.5,0.6-0.8,0.7c-0.3,0.1-0.6,0.2-1,0.2c-0.5,0-0.9-0.1-1.2-0.4
 c-0.3-0.3-0.5-0.7-0.5-1.2c0-1.1,0.9-1.6,2.6-1.7l0.9,0v-0.3c0-0.4-0.1-0.7-0.3-0.9c-0.2-0.2-0.5-0.3-0.9-0.3c-0.4,0-1,0.1-1.5,0.4
 l-0.2-0.6c0.3-0.1,0.6-0.3,0.9-0.3c0.3-0.1,0.6-0.1,0.9-0.1c0.6,0,1.1,0.1,1.4,0.4c0.3,0.3,0.5,0.7,0.5,1.4v3.7H162.8z M160.9,59.8
 c0.5,0,0.9-0.1,1.2-0.4c0.3-0.3,0.4-0.7,0.4-1.2v-0.5l-0.8,0c-0.6,0-1.1,0.1-1.4,0.3c-0.3,0.2-0.4,0.5-0.4,0.8
 c0,0.3,0.1,0.5,0.3,0.7C160.4,59.8,160.6,59.8,160.9,59.8z"
    />
    <path
      class="st0"
      d="M168.8,60.4V57c0-0.4-0.1-0.8-0.3-1s-0.5-0.3-0.9-0.3c-0.6,0-1,0.2-1.2,0.5s-0.4,0.8-0.4,1.5v2.8h-0.8v-5.4h0.7
 l0.1,0.7h0c0.2-0.3,0.4-0.5,0.7-0.6c0.3-0.1,0.6-0.2,1-0.2c0.6,0,1.1,0.2,1.5,0.5c0.3,0.3,0.5,0.8,0.5,1.5v3.5H168.8z"
    />
    <path
      class="st0"
      d="M175.1,59.7L175.1,59.7c-0.4,0.5-1,0.8-1.7,0.8c-0.7,0-1.2-0.2-1.6-0.7c-0.4-0.5-0.6-1.2-0.6-2s0.2-1.6,0.6-2.1
 s0.9-0.7,1.6-0.7c0.7,0,1.3,0.3,1.7,0.8h0.1l0-0.4l0-0.4v-2.2h0.8v7.6h-0.7L175.1,59.7z M173.5,59.8c0.6,0,1-0.2,1.2-0.5
 c0.2-0.3,0.4-0.8,0.4-1.5v-0.2c0-0.8-0.1-1.3-0.4-1.6c-0.3-0.3-0.7-0.5-1.2-0.5c-0.5,0-0.8,0.2-1.1,0.6c-0.3,0.4-0.4,0.9-0.4,1.6
 c0,0.7,0.1,1.2,0.4,1.5S173,59.8,173.5,59.8z"
    />
    <path
      class="st0"
      d="M182.9,60.5c-0.3,0-0.7-0.1-1-0.2c-0.3-0.1-0.5-0.3-0.7-0.6h-0.1c0,0.3,0.1,0.6,0.1,0.9v2.2h-0.8v-7.8h0.7
 l0.1,0.7h0c0.2-0.3,0.5-0.5,0.7-0.6s0.6-0.2,1-0.2c0.7,0,1.3,0.2,1.6,0.7c0.4,0.5,0.6,1.2,0.6,2c0,0.9-0.2,1.6-0.6,2
 C184.2,60.3,183.6,60.5,182.9,60.5z M182.8,55.7c-0.5,0-0.9,0.2-1.2,0.5s-0.4,0.8-0.4,1.4v0.2c0,0.8,0.1,1.3,0.4,1.6
 c0.3,0.3,0.7,0.5,1.2,0.5c0.5,0,0.8-0.2,1.1-0.6c0.3-0.4,0.4-0.9,0.4-1.5c0-0.7-0.1-1.2-0.4-1.5C183.7,55.8,183.3,55.7,182.8,55.7z"
    />
    <path
      class="st0"
      d="M190,60.4l-0.2-0.8h0c-0.3,0.3-0.5,0.6-0.8,0.7c-0.3,0.1-0.6,0.2-1,0.2c-0.5,0-0.9-0.1-1.2-0.4
 c-0.3-0.3-0.5-0.7-0.5-1.2c0-1.1,0.9-1.6,2.6-1.7l0.9,0v-0.3c0-0.4-0.1-0.7-0.3-0.9c-0.2-0.2-0.5-0.3-0.9-0.3c-0.4,0-1,0.1-1.5,0.4
 l-0.2-0.6c0.3-0.1,0.6-0.3,0.9-0.3c0.3-0.1,0.6-0.1,0.9-0.1c0.6,0,1.1,0.1,1.4,0.4c0.3,0.3,0.5,0.7,0.5,1.4v3.7H190z M188.2,59.8
 c0.5,0,0.9-0.1,1.2-0.4c0.3-0.3,0.4-0.7,0.4-1.2v-0.5l-0.8,0c-0.6,0-1.1,0.1-1.4,0.3c-0.3,0.2-0.4,0.5-0.4,0.8
 c0,0.3,0.1,0.5,0.3,0.7C187.6,59.8,187.8,59.8,188.2,59.8z"
    />
    <path
      class="st0"
      d="M191.5,55.1h0.9l1.2,3.1c0.3,0.7,0.4,1.2,0.5,1.5h0c0-0.2,0.1-0.5,0.3-0.9c0.1-0.4,0.6-1.6,1.3-3.7h0.9
 l-2.3,6.1c-0.2,0.6-0.5,1-0.8,1.3c-0.3,0.3-0.7,0.4-1.1,0.4c-0.2,0-0.5,0-0.7-0.1v-0.6c0.2,0,0.4,0.1,0.6,0.1c0.6,0,1-0.3,1.2-0.9
 l0.3-0.8L191.5,55.1z"
    />
    <path
      class="st0"
      d="M200.8,60.4l-0.2-0.8h0c-0.3,0.3-0.5,0.6-0.8,0.7c-0.3,0.1-0.6,0.2-1,0.2c-0.5,0-0.9-0.1-1.2-0.4
 c-0.3-0.3-0.5-0.7-0.5-1.2c0-1.1,0.9-1.6,2.6-1.7l0.9,0v-0.3c0-0.4-0.1-0.7-0.3-0.9c-0.2-0.2-0.5-0.3-0.9-0.3c-0.4,0-1,0.1-1.5,0.4
 l-0.2-0.6c0.3-0.1,0.6-0.3,0.9-0.3c0.3-0.1,0.6-0.1,0.9-0.1c0.6,0,1.1,0.1,1.4,0.4c0.3,0.3,0.5,0.7,0.5,1.4v3.7H200.8z M199,59.8
 c0.5,0,0.9-0.1,1.2-0.4c0.3-0.3,0.4-0.7,0.4-1.2v-0.5l-0.8,0c-0.6,0-1.1,0.1-1.4,0.3c-0.3,0.2-0.4,0.5-0.4,0.8
 c0,0.3,0.1,0.5,0.3,0.7C198.4,59.8,198.7,59.8,199,59.8z"
    />
    <path
      class="st0"
      d="M205.7,55c0.7,0,1.2,0.2,1.6,0.7c0.4,0.5,0.6,1.2,0.6,2s-0.2,1.6-0.6,2c-0.4,0.5-0.9,0.7-1.6,0.7
 c-0.3,0-0.7-0.1-1-0.2c-0.3-0.1-0.5-0.3-0.7-0.6H204l-0.2,0.7h-0.6v-7.6h0.8v1.8c0,0.4,0,0.8,0,1.1h0C204.4,55.2,205,55,205.7,55z
  M205.6,55.7c-0.6,0-1,0.2-1.2,0.5c-0.2,0.3-0.4,0.9-0.4,1.6s0.1,1.3,0.4,1.6c0.3,0.3,0.7,0.5,1.2,0.5c0.5,0,0.9-0.2,1.1-0.5
 c0.2-0.4,0.4-0.9,0.4-1.6c0-0.7-0.1-1.2-0.4-1.6S206.1,55.7,205.6,55.7z"
    />
    <path class="st0" d="M210.3,60.4h-0.8v-7.6h0.8V60.4z" />
    <path
      class="st0"
      d="M214.4,60.5c-0.8,0-1.4-0.2-1.9-0.7c-0.5-0.5-0.7-1.2-0.7-2c0-0.9,0.2-1.5,0.6-2.1c0.4-0.5,1-0.8,1.7-0.8
 c0.7,0,1.2,0.2,1.6,0.7c0.4,0.4,0.6,1,0.6,1.7v0.5h-3.7c0,0.6,0.2,1.1,0.5,1.4c0.3,0.3,0.7,0.5,1.3,0.5c0.6,0,1.1-0.1,1.7-0.4v0.7
 c-0.3,0.1-0.6,0.2-0.8,0.3C215.1,60.5,214.8,60.5,214.4,60.5z M214.2,55.6c-0.4,0-0.8,0.1-1,0.4c-0.3,0.3-0.4,0.7-0.5,1.2h2.8
 c0-0.5-0.1-0.9-0.3-1.2C215,55.8,214.6,55.6,214.2,55.6z"
    />
  </svg>
</template>
<script>
  export default {};
</script>
<style lang="scss" scoped>
 .st0{
   fill:#10A7B8;
  }
</style>