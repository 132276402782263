<template>
  <div class="ez-user">
    <router-link to="/account">
      <EzAvatar :user="this.$store.state.auth.user" />
    </router-link>
  </div>
</template>

<script>

import EzAvatar from '@/components/EzAvatar.vue';

export default {
  data() {
    return {}
  },
  components: {
    EzAvatar
  }
}
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/_variables.scss';
  .ez-user {
    &__avatar {
      height: 3rem;
      width: 3rem;
      border-radius: 999px;
      color: white;
      background: $accent;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
    &__dropdown {
      position: fixed;
      width: 18.5rem;
      background-color: white;
      margin-top: 1rem;
      border-radius: 1rem;
      right: 5rem;

      ul {
        list-style: none;
        li {
          cursor: pointer;
          height: 3rem;
          display: flex;
          align-items: center;
        }
      }
    }
  }
</style>