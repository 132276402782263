import axios from "axios"

export default {

  getCookie: async () => axios.get(process.env.VUE_APP_API_URL + '/sanctum/csrf-cookie'),

  login: async (email, password, remember) => axios.post(process.env.VUE_APP_API_URL + '/ui/login', { email: email, password: password, remember: remember }),

  logout: async () => axios.get(process.env.VUE_APP_API_URL + '/ui/logout'),

  me: async () => axios.get(process.env.VUE_APP_API_URL + '/ui/me'),
   
  passwordReset: async (passwordRequest) => axios.post(process.env.VUE_APP_API_URL + '/ui/users/password-reset', passwordRequest),
  
  passwordUpdate: async (passwordRequest) => axios.post(process.env.VUE_APP_API_URL + '/ui/users/password-update', passwordRequest),

}