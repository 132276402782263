<template>
  <button class="ez-button transition" v-bind="{ type: 'button' }"><slot></slot></button>
</template>

<script>
export default {
  props: {
    
  }
}
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/_variables.scss';
  .ez-button {
    border: none;
    height: 3rem;
    border-radius: 3rem;
    color: white;
    background: $accent-darkened;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1rem;
    font-family: $font;
    font-weight: 400;
  }
  .ez-button.icon {
    width: 3rem;
    padding: 0;
  }
  .ez-button:hover {
    background: $accent;
    box-shadow: 0 5px 15px rgba(0,0,0,0.15);
  }

  .ez-button.small {
    height: 1.75rem;
    width: 1.75rem;
    border-radius: 1.75rem;
  }
  .ez-button.small.icon {
    width: 1.75rem;
  }
  .ez-button.primary {
    background: $primary;
  }
  .ez-button.primary:hover {
    background: $primary-darkened;
  }
  .ez-button.dark {
    background: $grey;
  }
  .ez-button.dark:hover {
    background: $dark-grey;
  }
</style>