<template>
  <div>
    <router-view />
  </div>
</template>
<script>
export default {

}
</script>
<style lang="scss">
  @import "./assets/styles/index.scss";
  body {
    &::after {
      position: fixed;
      right: 0;
      bottom: 0;
      content: '';
      height: 35%;
      width: 45%;
      background-image: url(./assets/images/background-elem.svg);
      z-index: -2;
    }
  }
</style>
