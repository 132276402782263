import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store';
import interceptorsSetup from './interceptors'

import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';

const app = createApp(App);

app.use(VueToast);
app.use(router);
app.use(store);

interceptorsSetup();

app.mount('#app');