<template>
  <!-- <img class="ez-user-avatar" v-if="user.avatar" :src="user.avatar" /> -->
  <div class="ez-user-avatar">{{ user.name.length ? user.name[0] : '?' }}</div>
</template>

<script>
export default {
  props: {
    user: {}
  }
}
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/_variables.scss';
  .ez-user-avatar {
    height: 3rem;
    width: 3rem;
    border-radius: 3rem;
    color: white;
    background: $accent;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-weight: bold;
  }
</style>