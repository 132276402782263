import axios from "axios"

const baseUrl = process.env.VUE_APP_API_URL;

export default {

  get() {
    return axios.get(baseUrl + "/ui/tenants");
  }

}