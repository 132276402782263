<template>
  <div v-if="loaded">
    <EzNav v-show="this.$route.name !== 'Login'" />

    <div id="main">
      <div id="toolbar" v-show="this.$route.name !== 'Login'">
        <div class="toolbar__left">
          <EzButton v-if="(this.$router.currentRoute.value.path.match(/\//g) || []).length > 1" class="dark" style="min-width: 100px" @click="goBack"><div>Terug</div></EzButton>
        </div>
        <div class="toolbar__spacer"></div>
        <div class="toolbar__right">
          <EzTenants />
          <div class="toolbar__border"></div>
          <EzUser />
        </div>
      </div>
      <div id="content">
        <router-view/>
      </div>
    </div>
  </div>

</template>

<script>
  import EzNav from '@/components/layout/EzNav.vue';
  import EzTenants from '@/components/EzTenants.vue';
  import EzUser from '@/components/EzUser.vue';
  import EzButton from '@/components/EzButton.vue';

  export default {
    components: {
      EzNav,
      EzUser,
      EzTenants,
      EzButton
    },
    data: () => {
      return {
        loaded: false
      }
    },
    mounted() {
      this.$store.dispatch('auth/me').then(() => {
        this.loaded = true
      });
    },
    methods: {
      goBack() {
        this.$router.back();
      }
    }
  }
</script>

<style lang="scss">
  @import "./assets/styles/index.scss";

  #main {
    margin-left: 20rem;
    height: 100vh;
    position: relative;
  }
  
  #toolbar {
    width: 100%;
    height: 8.5rem;
    display: flex;
    align-items: center;

    & .toolbar__left {
      padding-left: 6.25rem;
    }
    & .toolbar__spacer {
      width: 100%;
    }

    & .toolbar__right {
      display: flex;
      padding-right: 6.25rem;
    }

    & .toolbar__border {
      margin: 0 1rem;
      border-left: 1px solid $mid-grey;
      height: 48px;
    }
  }

  #content {
    padding: 1rem 6.25rem 1rem 6.25rem;
  }
</style>