import records from '@/api/records'

const state = () => ({
  selectedRecordIds: [],
  selectedProcessId: '',
  loading: false,
})

const mutations = {
  select: (state, recordId) => { 
    state.selectedRecordIds.push(recordId)
  },
  setActiveProcess: (state, processId) => { 
    state.selectedProcessId = processId
  },
  reset: (state) => { 
    state.selectedRecordIds = []; 
    state.processId = '' ;
    state.loading = false;
  },
  unselect: (state, recordId) => {
    let index = state.selectedRecordIds.findIndex(item => item === recordId);
    state.selectedRecordIds.splice(index, 1);
  },
  setLoading: (state) => {
    state.loading = true;
  },
  unsetLoading: (state) => {
    state.loading = false;
  }
}

const actions = {

  deleteSelected: async ({rootState, commit, dispatch}) => {
    commit('setLoading');
    //we want to be sure that all calls are executed...
    const deletePromises = rootState.records.selectedRecordIds.map(recordId =>
        dispatch('delete', recordId)
    );

    await Promise.all(deletePromises);

    commit('unsetLoading');
  },

  retrySelected: async ({rootState, commit, dispatch}) => {
    commit('setLoading');
    //we want to be sure that all calls are executed...
    const retryPromises = rootState.records.selectedRecordIds.map(recordId =>
      dispatch('retry', recordId)
    );
    await Promise.all(retryPromises);

    commit('unsetLoading');
  },

  retryAll: async ({rootState, commit, dispatch}) => {
    commit('setLoading');

    await dispatch('retryAllErrors', rootState)

    commit('unsetLoading');
  },

  retryAllErrors: async({rootState}) => {
    await records.retryAll(rootState.auth.activeTenantId, rootState.records.selectedProcessId);
  },

  delete: async({rootState, commit}, recordId) => {
    await records.delete(rootState.auth.activeTenantId, rootState.records.selectedProcessId, recordId);
    commit('unselect', recordId);
  },

  retry: async({rootState, commit}, recordId) => {
    await records.retry(rootState.auth.activeTenantId, rootState.records.selectedProcessId, recordId);
    commit('unselect', recordId);
  }

}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}