<template>
  <div class="ez-search">

    <div class="ez-search__input">
      <input v-model="searchTerm" :placeholder="this.$store.getters['auth/activeTenant'] ? 'Tenant: ' + this.$store.getters['auth/activeTenant'].name : 'Selecteer tenant'" @focus="showResults = true"  @blur="hideResults()"/>
    </div>

    <div class="ez-search__results transition" v-if="showResults" @focus="showResults = true">
      <ul>
        <li v-for="tenant in tenants" :key="tenant.name" @click="setActiveTenant(tenant.id)" v-bind:class="{ active: tenant.id === this.activeTenantId }">{{ tenant.name }}</li>
        <li v-if="searchTerm !== '' && tenants.length < 1">Geen tenants gevonden</li>
      </ul>
    </div>

  </div>
</template>

<script>
import store from '@/store';
import router from '@/router';

export default {
  data() {
    return {
      searchTerm: '',
      showResults: false,
    }
  },
  computed: {
    activeTenantId: () => store.state.auth.activeTenantId,
    tenants() {
      return store.state.auth.tenants.filter(tenant => { 
          let tenantName = tenant.name.toLowerCase();
          let searchTerm = this.searchTerm.toLowerCase();
          return tenantName.includes(searchTerm.toLowerCase());
        })
    }
  },
  methods: {
    setActiveTenant(tenantId) {
      if (tenantId !== this.activeTenantId) {
        store.commit('auth/setActiveTenantId', tenantId);
        this.showResults = false;

        /** Force refresh */

        router.currentRoute.value.path === '/dashboard' ?
          router.go(0) :
          router.push('/dashboard');
      }
    },
    hideResults(){
      setTimeout(() => { this.showResults = false; }, 200);
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/_variables.scss';
  .ez-search {
    height: 3rem;

    .ez-search__input  {
      height: 3rem;
      outline: 0;
      width: 18.5rem;
      border-radius: 9999px;
      padding: 0 0;
      position: relative;
      background: white;
      overflow: hidden;
      display: flex;
      align-items: center;
      cursor: text;
      
      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        border-radius: 9999px;
        border: 2px solid $mid-grey;
      }

      &:focus-within {
        &::after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            border-radius: 9999px;
            border: 2px solid $accent;
        }
        &::before {
          content: url(../assets/images/search-icon.svg);
        }
      }

      &::before {
        position: absolute;
        top: auto;
        bottom: auto;
        left: 0.5rem;
        width: 22px; 
        height: 22px;
        content: url(../assets/images/search-icon-grey.svg);
        z-index: 2;
      }

      & input {
        width: 100%;
        height: 100%;
        border: 0;
        outline: 0;
        padding: 0 1.25rem 0 2.25rem;
        background: none;
      }

    }

    & .ez-search__results {
      z-index: 9999;
      box-shadow: 0 10px 35px rgba(0,0,0,0.35);
      position: fixed;
      width: 18.5rem;
      background-color: white;
      margin-top: 1rem;
      border-radius: 1rem;
      ul {
        list-style: none;
        padding: 0;
        li {
          cursor: pointer;
          height: 3rem;
          display: flex;
          align-items: center;
          padding: 0 1rem;
          &.active {
            color: #10A7B8;
            background-color: none;
            cursor: default;
            pointer-events: none;
          }
          &:hover {
            background-color: rgba(0,0,0,0.1);
          }
        }
      }
    }
  }
</style>