import axios from "axios"

const baseUrl = process.env.VUE_APP_API_URL;

export default {

  get: (tenantId, processId, type, page = 1) => {

    let request = null;
    let options = {
      params: {
        page: page
      }
    };

    switch (type) {
      case 'COMPLETED':
        request = axios.get(baseUrl + `/ui/tenants/${tenantId}/processes/${processId}/records/completed`, options);
        break;
      case 'FAILED':
        request = axios.get(baseUrl + `/ui/tenants/${tenantId}/processes/${processId}/records/failed`, options);
        break;
      case 'PENDING':
        request = axios.get(baseUrl + `/ui/tenants/${tenantId}/processes/${processId}/records/pending`, options);
        break;
      default:
        request = axios.get(baseUrl + `/ui/tenants/${tenantId}/processes/${processId}/records`, options);
        break;
    }

    return request;
  },

  getOne: (tenantId, processId, recordId) => axios.get(baseUrl + `/ui/tenants/${tenantId}/processes/${processId}/records/${recordId}`),
  retry: (tenantId, processId, recordId) => axios.get(baseUrl + `/ui/tenants/${tenantId}/processes/${processId}/records/${recordId}/retry`),
  delete: (tenantId, processId, recordId) => axios.delete(baseUrl + `/ui/tenants/${tenantId}/processes/${processId}/records/${recordId}/delete`),
  retryAll: (tenantId, processId) => axios.get(baseUrl + `/ui/tenants/${tenantId}/processes/${processId}/retry-all-errors`)
}