import axios from "axios"

const baseUrl = process.env.VUE_APP_API_URL;

export default {

  get(tenantId) {
    return axios.get(baseUrl + `/ui/tenants/${tenantId}/links`);
  },

  getOne(tenantId, linkId) {
    return axios.get(baseUrl + `/ui/tenants/${tenantId}/links/${linkId}`)
  }
  
}