import auth from '@/api/auth';
import tenants from '@/api/tenants';
import router from '@/router';

const state = () => ({
  user: {
    name: '',
    email: '',
    role: 'superuser',
    avatar: 'https://i.pravatar.cc/300?u=123',
  },
  tenants: [],
  activeTenantId: '',
  authenticated: false
})

const getters = {
  activeTenant: state => state.tenants.find(tenant => tenant.id === state.activeTenantId),
  authenticated: state => state.authenticated,
  me: state => state.user,
  role: state => state.user.role,
  auth: state => state,
}

const mutations = {

  /** User */

  setUser(state, payload) {
    state.user = Object.assign(state.user, payload);
    state.authenticated = true;
  },

  unsetUser(state) {
    state.user.name = '';
    state.user.email = '';
    state.user.role = 'superuser';
    state.authenticated = false;
  },

  /** Active tenant */

  setActiveTenantId(state, tenantId) {
    state.activeTenantId = tenantId;
    localStorage.setItem('activeTenantId', tenantId);
  },

  unsetActiveTenantId(state) {
    state.activeTenantId = '';
    localStorage.removeItem('activeTenantId');
  },

  /** Tenants */

  unsetAvailableTenants(state) {
    state.tenants = [];
  },

  setAvailableTenants(state, payload) {
    state.tenants = payload;
  }
}

const actions = {
  async login ({ dispatch }, credentials) {
    await auth.getCookie();
    await auth.login(credentials.email, credentials.password, credentials.remember);
    await dispatch('me');
  },

  async logout ({commit}) {
    auth.logout().then(() => {
      localStorage.removeItem('loginRemember', false);
      commit('unsetUser');
      commit('unsetActiveTenantId');
      commit('unsetAvailableTenants');
      router.push('/');
    });
  },

  async me({ commit, dispatch, state }) {
    let me = await auth.me();

    if (me) {
      commit('setUser', me.data);
      await dispatch('fetchTenants').then(() => {
        if (localStorage.getItem('activeTenantId') && state.tenants.find(tenant => tenant.id === localStorage.getItem('activeTenantId'))) {
          commit('setActiveTenantId', localStorage.getItem('activeTenantId'));
        } else if (state.tenants?.length === 1) {
          commit('setActiveTenantId', state.tenants[0].id);
        } else if (state.tenants?.length > 1) {
          router.push('/tenants');
        } else {
          commit('unsetActiveTenantId');
        }
      });
    }
    
  },
  
  async fetchTenants({ commit }) {
    let response = await tenants.get();
    commit('setAvailableTenants', response.data);
  },

}


export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}