import integrations from '@/api/integrations';

const state = () => ({
  data: [],
  selectedIntegrationIds: [],
  currentPage: 0,
})

const mutations = {
  // refresh all data;
  refresh(state, payload) {
    state.data = payload;
  },
  // update existing item in data
  update(state, payload) {
    let index = state.data.findIndex(link => link.id === payload.id)
    if (index > -1) {
      state.data[index] = payload;
    } else {
      state.data.push(payload);
    }
  },
  // set next page
  nextPage(state) {
    state.currentPage++;
  },
  // set previous page
  previousPage(state) {
    if (state.currentPage > 0) {
      state.currentPage--;
    }
  },
  // add or remove integration
  toggleIntegration(state, integrationId) {
    if (state.selectedIntegrationIds.includes(integrationId)) {
      const arrayIndex = state.selectedIntegrationIds.findIndex(item => item === integrationId);
      state.selectedIntegrationIds.splice(arrayIndex, 1);
    } else {
      state.selectedIntegrationIds.push(integrationId);
    }
  },
  // submits form items
  submit(state) {
    console.log(state.getters.selected);
  },
  // resets form
  resetForm(state) {
    state.currentPage = 0;
    state.selectedIntegrationIds = [];
  }
}

const actions = {
  // Fetch integrations from API
  async fetch({commit}) {
    const result = await integrations.get();
    commit('refresh', result.data);
  }
}

const getters = {
  // returns selected integrations only
  selected(state) {
    return state.data.filter(integration => state.selectedIntegrationIds.find(item => item === integration.id ));
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}