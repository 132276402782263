import { createRouter, createWebHistory } from 'vue-router'
import AppLayout from '../AppLayout.vue'
import store from '../store'

let authState = async () => await store.getters['auth/auth'];

const routes = [
  {
    path: '',
    name: 'void',
    redirect: '/dashboard'
  },
  {
    path: '/:catchAll(.*)',
    name: '404',
    redirect: '/dashboard'
  },
  {
    path: '/',
    name: 'root',
    redirect: '/dashboard'
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/auth/Login.vue'),
    meta: { authenticate: false },
  },
  {
    path: '/logout',
    name: 'Logout',
    component: () => import('../views/auth/Logout.vue'),
    meta: { authenticate: true },
  },
  {
    route: '/app',
    component: AppLayout,
    meta: { authenticate: true },
    children: [
      {
        path: '/tenants',
        name: 'Tenants',
        // meta: { authorize: 'manager|admin|superuser' },
        component: () => import('../views/Tenants.vue')
      },
      { 
        path: '/dashboard',
        name: 'Dashboard',
        component: () => import('../views/Dashboard.vue')
      },
      { 
        path: '/dashboard-management',
        name: 'ManagementDashboard',
        component: () => import('../views/DashboardManagement.vue')
      },
      { 
        path: '/users',
        name: 'Users',
        // meta: { authorize: 'manager|admin|superuser' },
        component: () => import('../views/users/Index.vue')
      },
      { 
        path: '/users/new',
        name: 'NewUser',
        // meta: { authorize: 'manager|admin|superuser' },
        component: () => import('../views/users/Create.vue')
      },
      {
        path: '/integrations',
        name: 'Integrations',
        // meta: { authorize: 'user|admin|superuser' },
        component: () => import('../views/Integrations.vue')
      },
      { 
        path: '/account',
        name: 'Account',
        component: () => import('../views/auth/Account.vue')
      },
      {
        path: '/link/:id',
        name: 'Link',
        component: () => import('../views/Link.vue')
      },
      {
        path: '/link/:linkId/process/:processId',
        name: 'Process',
        component: () => import('../views/Process.vue')
      },
    ]
  }
]

/** Set router */

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

/** Filter roles */

authState().then(state => {

  /** Check for authenticated|authorized states */

  router.beforeEach((to, from, next) => {

    //console.warn(to);
    //console.warn(to.name);
    //console.warn(state.authenticated);

    if(state.authenticated && (to.name === 'void' || to.name === 'root')){
      next('/dashboard'); return
    }
    if(to.meta.authenticate && ! state.authenticated ) {
      console.warn('route: not authenticated'); next('/login'); return
    }
    if(to.meta.authorize && ! to.meta.authorize.match(new RegExp('(^|[^a-z]+)' + state.user.role + '([^a-z]+|$)','gi')) ) {
      console.warn('route: not authorized: '+ to.meta.authorize + ' => ' + state.user.role); next('/login'); return
    }
    next(); return

  })

});

export default router
