import axios from 'axios';

const baseUrl = process.env.VUE_APP_API_URL;

export default new class {

  get = async (tenantId) => axios.get(baseUrl + `/ui/tenants/${tenantId}/users`);

  create = async (tenantId, user) => axios.post(baseUrl + `/ui/tenants/${tenantId}/users`, user);

  update = async (tenantId, user) => axios.put(baseUrl + `/ui/tenants/${tenantId}/users${user.id ? '/' + user.id : ''}`, user);

  me = async (user) => axios.put(baseUrl + `/ui/me`, user);

  passwordUpdate = async (passwordRequest) => axios.put(process.env.VUE_APP_API_URL + '/ui/users/password', passwordRequest);

  delete = async (tenantId, user) => axios.delete(baseUrl + `/ui/tenants/${tenantId}/users/${user.id}`);

}