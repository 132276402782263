import axios from 'axios';
import router from './router';


export default function setup() {

    axios.defaults.withCredentials = true;
    axios.defaults.headers.common["Accept"] = "application/json";

    axios.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
          console.warn(error);
            if (error.response.status === 401) {
                router.push('/logout');
            }
          return Promise.reject(error);
        }
    );

}
