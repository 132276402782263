import { createStore } from 'vuex';

import auth from './modules/auth';
import links from './modules/links';
import processes from './modules/processes';
import users from './modules/users';

import createPersistedState from "vuex-persistedstate";
import records from './modules/records';
import integrations from './modules/integrations';

export default createStore({
  modules: {
    auth: auth,
    links: links,
    processes: processes,
    records: records,
    integrations: integrations,
    users: users,
  },
  plugins: [createPersistedState()]
})