<template>  
  <div id="sidebar">
    <div class="sidebar__logo">
      <router-link to="/dashboard" class="transition">
        <EzLogo />
      </router-link>
    </div>

    <ul class="nav-list">
      <li v-if="activeTenant">
        <router-link to="/dashboard" class="transition">
          <span>Dashboard</span>
        </router-link>
      </li>
      <li v-if="superuser">
        <router-link to="/dashboard-management" class="transition">
          <span>Management</span>
        </router-link>
      </li>
      <li v-if="superuser">
        <router-link to="/users" class="transition">
          <span>Gebruikers</span>
        </router-link>
      </li>
      <li>
        <router-link to="/account" class="transition">
          <span>Mijn Account</span>
        </router-link>
      </li>
      <li v-if="!user">
        <router-link to="/tenants" class="transition">
          <span>Omgevingen</span>
        </router-link>
      </li>
      <li>
        <router-link to="/logout" class="transition">
          <span>Uitloggen</span>
        </router-link>
      </li>
    </ul>

  </div>
</template>
<script>
  import EzLogo from '@/components/EzLogo.vue';
  import store from '@/store';

  export default {
    data() {
      return {}
    },
    computed:{
      user: () => store.state.auth.user.role === 'user',
      manager: () => store.state.auth.user.role === 'manager',
      admin: () => store.state.auth.user.role === 'admin',
      superuser: () => store.state.auth.user.role === 'superuser',
      activeTenant: () => store.state.auth.activeTenantId,
    },
    components: {
      EzLogo
    },
    mounted(){
      console.warn(store.state.auth.user.role);
    }
  }
</script>
<style lang="scss" scoped>

  @import '@/assets/styles/_variables.scss';

  #sidebar {
    width: 15rem;
    height: 100vh;
    background: white;
    position: fixed;
    z-index: 10;
    & .sidebar__logo {
      padding: 2.25rem 2.875rem;
    }
  }

  .nav-list {
    list-style: none;
    font-size: 1.1rem;
    & li {
      height: 3rem;
      & a {
        width: 100%;
        height: 100%;
        color: $mid-grey;
        vertical-align: baseline;
        display: flex;
        align-items: center;
        text-decoration: none;
        &.router-link-active {
          color: $accent;
          font-weight: 600;
        }
        &:not(.router-link-active):hover {
          font-weight: 600;
          color: $grey
        }
      }
    }
  }

</style>